import { store } from "./state.js"

export default class Command {

// TODO: add error handling for bad input
    constructor(deviceCommand) {

        this.topic = Math.floor(Math.random() * (900000 - 1) + 1);
        this.payload = deviceCommand
        this.connection = store.connection
        this.specialCommand = JSON.parse(deviceCommand).isSpecial

        //make a cool object to send to the server
        this.messagebuilder = {
            topic : this.topic,
            payload : this.payload,
        }

        console.log('Sending command =>', JSON.parse(this.messagebuilder.payload))

    }

   

    async send() {
       
        try {

            store.reset();
            var stringMessage = JSON.stringify(this.messagebuilder);
            
            store.deviceInfo.commandAuth.isSpecial = this.specialCommand;
            await this.connection.send(stringMessage);
        
        }catch(err) {

            console.log(err)
        }
        
    }

}