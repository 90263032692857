import { reactive } from 'vue'
import Thermostat from '@/helpers/thermostat';
import Mini from '@/helpers/mini';
import Water from '@/helpers/water';
import Electric from '@/helpers/electric';
import Router from '@/helpers/router';
import Strega from '@/helpers/strega';
import Rope from '@/helpers/rope';
import TempHumid from '@/helpers/tempHumid';  

export const store = reactive({

  // app info
  engineeringUsers: ['9','24','2429','100'],
  tree: [],
  connection: null,

  // site info 
  base: process.env.VUE_APP_HOSTBASE,
  expandedArr: {
    id: 1
  },
  expanded: [],
  newestChildrenData: null,

  siteViewType: {

    siteType: 'propertyDetails'

  },

  node: null,
  depth: 2,
  selector: 'list',

  n: 7,
  isDivVisible: true,

  // user info  
  user: {
    id: null,
    name: null,
    email: null,
  },

  // property info
  noproperties: false,
  property: {
    id: null,
    name: null,
    zip: null,
  },

  propertyIndex: null,



  // building info

  building: {
    id: null,
    name: null,
  },
  buildingsIndex: null,
  noBuildings: false,



  // unit info

  unit: {
    id: null,
    name: null,
    checked: 0,
  },

  unitIndex: null,
  showSetExceptions: true,
  showRemoveExceptions: false,

  showVacant: true,
  showRemoveVacant: false,

  start_date: null,
  end_date: null,





  // device info

  deviceName: null,
  devicesIndex: null,
  expandedDetails: [],
  device: null,      // the device we are working with (should be only one)

  // list of device qrs for device type identification
  tstatQrs: [92, 105, 36, 33, 34, 35, 67, 1006, 1009,118],
  miniQrs: [37, 38, 77, 39, 63],
  waterQrs: [2,4,16,17,21,46, 1000,1001,1005,110,111,112,107,108,26,27],
  pulseQrs: [43,104,106,73,81,75,84],
  waterQrsPing: [1001,26,27,21],
  electricQrs: [20,40,23,109,25,1012,102,57,101,41,62,76,103,83,19,18,1014],
  electricQrsPing: [20,23,109,25,19,18],
  routerQrs: [9, 53, 12],
  gatewayQrs: [48],
  stregaQrs: [80],
  ropeQrs: [82],
  tempHumidQrs: [64, 60],

  chartData: {
    labels: ['12 pm', '1 pm', '2 pm', '3 pm', '4 pm', '5 pm', '6 pm'],
    datasets: [],

  },

  chartType: null,
  timeFrameSelector: 'hour',
  chartUrl: null,

  /*
    {
          label: 'Indoor Temperature',
          backgroundColor: '#FFD700',
          borderColor: '#FFD700',
          pointStyle: false,
          data: [77, 75, 78, 77, 78, 79, 78]
        },
        {
          label: 'Outdoor Temperature',
          backgroundColor: '#77dd77',
          borderColor: '#77dd77',
          pointStyle: 'circle',
          data: [47, 45, 48, 47, 48, 49, 49]
        },
  */


  chartOptions: {
    responsive: true,

    tension: 0,
    scales: {
      x: {
        type: 'time',
      },
      y: {
        ticks: {
          callback: function (value) {
            return value.toFixed(0) + '°F'; // Add the Fahrenheit symbol to the label
          }
        }
      }
    },
    plugins: {
      legend: {
        postion: 'bottom',
      },
    }
  },



  deviceInfo: {

    // stuff i get from the db 
    meta1: {
      header_common_device_type: null,
      sensor_radio_type: null,
      header_common_firmware_version: null,
      insert_date: null,
      interval_milis: null,
    },

    //stuff i get from the api
    meta2: {
      attached_to: "HVAC",
      device_part_number: "T1000",
      eui: "0022A3000035FB77",
      factor: 1,
      gid_set_date: "1900-01-01T00:00:00.000Z",
      gid_set_flag: 0,
      last_hostname: "FFFF0025310561BF",
      location: "None",
      meter_class: "Submeter",
      qr: 254,
      repair_flag: 0,
      repair_flag_date: "1900-01-01T00:00:00.000Z",
      repair_flag_reason: "None Selected",
      unique_id: "548465",
    },

    // stuff that I get from the packet
    sensor_data: {
      mode:  null,
      fan_mode: null,
      fan_active: null,
      fan_events: null,
      cool_active: null,
      cool_events: null,
      heat_active: null,
      heat_events: null,
      set_point_mode: null,
      cooling_set_point_f: null,
      fan_runtime_seconds: null,
      heating_set_point_f: null,
      cool_runtime_seconds: null,
      heat_runtime_seconds: null,
      indoor_temperature_f: null,
      set_point_mode_numeric: null,
      
    },
    command_response: null,
    set_point_limit_cool: null,
    set_point_limit_heat: null,


    commandAuth: {
      authState: null,
      gatewayStatus: null,
      authentication: null,
      problem: null,
      isSpecial: null,
    }

  },


  commandConfig: {

    //user info 
    user_id: null, 
    sk: null, 
    email: "dfisher@",
    show_advanced: 0,

    //device info 
    target_unique_id: null,
    target_property_id: null,
    hostname_or_source: null,
    qr: null,
    target_dev_eui: null,
    target_hostname_list: [],

    //command info
    command: null,
    command_token: "dfisher@6cXZ3xrAi3dRxMqDvJY75pMMwEizIDs68r57l4pP",
    payload: null,


  },

  

  liveDataStream: false,
  liveDataQueue: [],

  async fetchDbInfo(url) {

    console.log(url);

    try {
      var dbDataReq = await fetch(url);
      var latestData = await dbDataReq.json();
      return latestData;
    } catch (err) {
      console.log('there was an error fetching the db data', err);
    }

  },

  async nodeInfo(node, depth) {
    console.log('in node info', depth, node);
    this.node = node;
    console.log('node in the state:', node);
    this.depth = depth;
    this.expanded.push(node);



    switch (depth) {
      case 2:

        // set the vars for the property details view
        this.propertyIndex = this.tree.indexOf(node);
        this.commandConfig.target_property_id = node.id;
        this.commandConfig.show_advanced = node.show_advanced;
        this.user.show_advanced = node.show_advanced;
        
        //create the property object
        this.property = {
          id: node.id,
          name: node.name,
          zip: node.zip_code,
          buildings_or_floors: node.buildings_or_floors,
          devices_battery_count: node.devices_battery_count,
          devices_battery_percent: node.devices_battery_percent,
          devices_battery_reporting: node.devices_battery_reporting,
          devices_coord_count: node.devices_coord_count,
          devices_coord_percent: node.devices_coord_percent,
          devices_coord_reporting: node.devices_coord_reporting,
          devices_infrastructure_count:   node.devices_infrastructure_count,
          devices_infrastructure_percent: node.devices_infrastructure_percent,
          devices_infrastructure_reporting:  node.devices_infrastructure_reporting,
          devices_line_count: node.devices_line_count,
          devices_line_percent: node.devices_line_percent,
          devices_line_reporting: node.devices_line_reporting,
          excluded_from_leak_diagnostics: node.excluded_from_leak_diagnostics,
          gateway_count: node.gateway_count,
          number_of_leaks_found: node.number_of_leaks_found,
          number_of_overconsumption_found: node.number_of_overconsumption_found,
        };

        //check the property for gateway hostnames
        if (node.gateway_hostname != null) {
          this.property.noGateway = false;
          console.log('gateway split', node.gateway_hostname.split(','));
          this.property.target_hostname_list = node.gateway_hostname.split(',');
        } else {
          console.log('no gateway hostname');
          this.property.target_hostname_list = [];
          this.property.noGateway = true;
        }

        break;

      case 3:


        this.property = {
          ...this.property,
          id: node.property_id,
          name: node.property_name,

        }

        this.building = {
          id: node.id,
          name: node.name,
          gateway_count: node.gateway_count,
          placeholder_count: node.placeholder_count,
        };

        break;

      case 4:

        if(this.node.id != this.unit.id){
          console.log('unit is the same - removing checked property');
          this.unit.checked = 0;
        }else{
          console.log('unit is not the same - keeping checked property', this.unit.id, this.node.id);
          
          this.unit.checked = 1;
        }


        this.unit = {
          ...this.unit,
          id: node.id,
          name: node.name,
          vacant_flag: node.vacant_flag,
          exception_flag: node.exception_flag,
          configuration: node.configuration,
          occupant_count: node.occupant_count,
          gateway_count: node.gateway_count,
          placeholder_count: node.placeholder_count,
        }

        this.property = {
          ...this.property,
          id: node.property_id,
          name: node.property_name,

        }

        this.building = {
          ...this.building,
          id: node.building_id,
          name: node.building_name,

        }

        break;

      case 5:
        console.log('in the device case');
        this.devicesIndex = this.expanded.indexOf(node);
        this.deviceName = node.name;

        this.property = {
          ...this.property,
          id: node.property_id,
          name: node.property_name,

        }

        this.building = {
          ...this.building,
          id: node.building_id,
          name: node.building_name,

        }

        this.unit = {
          ...this.unit,
          id: node.apartment_id,
          name: node.apartment_name,
         

        }
        try {
          console.log('in the device try');
          //using the details of the specific device fetched from api , make a call to the db to get the rest of the info and last known sensor data.
          this.url = `${this.base}/api/database/fetchSensorInfo/${node.eui}`;
          var latestData = await this.fetchDbInfo(this.url);
          console.log('latest db data:', latestData);
          // build initial sensor data object
          //this.deviceInfo.sensor_data = latestData[0].sensor_data

          // building the initial meta1 object in device info 
          this.deviceInfo.meta1.header_common_device_type = latestData[0].header_common_device_type
          this.deviceInfo.meta1.sensor_radio_type = latestData[0].sensor_radio_type;
          this.deviceInfo.meta1.header_common_firmware_version = latestData[0].header_common_firmware_version;
          

        } catch (err) {
          console.log('there was an error fetching the db data', err);
        }


        try {

          this.deviceInfo.meta1.header_zigbee_unit_code = node.unit_code;

          // building the initial meta2 object using the details from api call to bill 
          this.deviceInfo.meta2 = this.expanded[this.devicesIndex];

          if (this.expanded[this.devicesIndex].real_time_status == 'unconfirmed') {
            this.deviceInfo.commandAuth.authState = 'unconfirmed';
          } else if (this.expanded[this.devicesIndex].real_time_status == 'offline') {
            this.deviceInfo.commandAuth.authState = 'offline';
          }

          //figure out the device type to create new device config in global state
          //probs shouldnt use the db info because of unconfirmed devices
          if (this.tstatQrs.includes(node.qr)) {
            console.log("Init new Thermostat");
            this.device = new Thermostat(this.property.id, this.expanded[this.devicesIndex], this.deviceInfo, this.commandConfig);
            this.device.ping();
            
          }

          else if (this.miniQrs.includes(node.qr)) {
            console.log("Init new Mini");
            this.device = new Mini(this.property.id, this.expanded[this.devicesIndex], this.deviceInfo, this.commandConfig);
            this.device.ping();
            
          }

          else if (this.waterQrs.includes(node.qr)  || this.pulseQrs.includes(node.qr)) {
            console.log(node.qr , "Init new Water Meter");
            this.device = new Water(this.property.id, this.expanded[this.devicesIndex], this.deviceInfo, this.commandConfig);
            this.deviceInfo.sensor_data = latestData[0].sensor_data;
          }
          else if(this.electricQrs.includes(node.qr)) {
            console.log(node.qr , "Init new Electric Meter");
            this.device = new Electric(this.property.id, this.expanded[this.devicesIndex], this.deviceInfo, this.commandConfig);
            this.deviceInfo.sensor_data = latestData[0].sensor_data;
          }
          else if (this.routerQrs.includes(node.qr)) {
            console.log(node.qr, "Init new Router");
            this.device = new Router(this.property.id, this.expanded[this.devicesIndex], this.deviceInfo, this.commandConfig);
            this.deviceInfo.sensor_data = latestData[0].sensor_data;
          }
          else if(this.stregaQrs.includes(node.qr)){
            console.log(node.qr, "Init new Strega");
            this.device = new Strega(this.property.id, this.expanded[this.devicesIndex], this.deviceInfo, this.commandConfig);
            //this.deviceInfo.sensor_data = latestData[0].sensor_data;
          } else if (this.ropeQrs.includes(node.qr)) {
            console.log(node.qr, "Init new Rope Sensor");
            this.device = new Rope(this.property.id, this.expanded[this.devicesIndex], this.deviceInfo, this.commandConfig);
            this.deviceInfo.sensor_data = latestData[0].sensor_data;
          } else if (this.tempHumidQrs.includes(node.qr)) {
            console.log(node.qr, "Init new Humid / Temp Sensor");
            this.device = new TempHumid(this.property.id, this.expanded[this.devicesIndex], this.deviceInfo, this.commandConfig);
            this.deviceInfo.sensor_data = latestData[0].sensor_data;
          }


        } catch (error) {
          console.log('try catch error')
          console.log(error);

        }

        break;

      default:
        break;
    }
  },
  async getChildren(url) {

    console.log('url:', url);

    try {
      this.newestChildrenData = null;
      var request = await fetch(url);
      var children = await request.json();

      if(children.error_message){
        console.log('error message', children.error_message);
        this.noChildren = true;
      
      }
      else{
        this.noChildren = false;
        this.newestChildrenData = children;
        console.log('newestchildrendata:', this.newestChildrenData);
      }


      return children;

    } catch (err) {

      console.log('hello there was an error fetching the children', err);

    }

  },

  async updateChart(type){

    console.log('in update chart', type);
    this.chartType = type;
    console.log('chart type actually set:', this.chartType);

  },

  duration: 19 * 1000,
  elapsed: 0,
  lastTime: 0,
  
  update: function () {

    this.elapsed = performance.now() - this.lastTime
    
    if (this.deviceInfo.commandAuth.authState != 'Command Success') {

        if (this.elapsed >= this.duration) {

            //stop the timer
            cancelAnimationFrame(this.handle)

            //set the specific problem if the time runs out
            if (this.deviceInfo.commandAuth.authState == 'auth_request_accepted') {
                this.problem = 'Gateway is not responding to command';
            } else if (this.deviceInfo.commandAuth.authState == 'auth_request_rejected') {
                this.problem = 'Auth rejected command';
            } else if (this.deviceInfo.commandAuth.authState == 'sent') {
                this.problem = 'Device is not responding to command within allotted time';
            } 

            //set the command to failed regardless of the reason
            console.log('timer up, command failed');
            this.deviceInfo.commandAuth.authState = 'Command Failed';

        } else {

           if (this.deviceInfo.commandAuth.authState == 'downlink_retry') {
            console.log('downlink retry detected, resetting timer');
            this.lastTime = performance.now();
          }else if (this.deviceInfo.commandAuth.authState == 'downlink_failed') {
             this.deviceInfo.commandAuth.authState = 'Command Failed';
          }

            //keep the timer running
            this.handle = requestAnimationFrame(this.update.bind(this))
        }
    } else {
        //if the command response comes in before the time runs out, stop the timer
        console.log('command success, stopping timer');
        cancelAnimationFrame(this.handle);
    }
},

  reset() {
      console.log('resetting progress bar')
      this.elapsed = 0
      this.lastTime = performance.now()
      this.update()
  },










})